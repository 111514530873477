/* global videojs */

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import './CSS/ProductTemplate.css';
import { initIRPlayer } from '../intoreality-player/src/init';
import useAuth from '../hooks/useAuth';
import { removeRotateScreen } from '../intoreality-player/src/render/screen-actions.js';

function ProductTemplate() {
  const navigate = useNavigate();
  const location = useLocation();

  const rawProductId = useParams().productId;
  const productId = rawProductId.replace(/\/$/, '');

  const [event, setEvent] = useState(null);
  const [error, setError] = useState(null);
  const [accessMessage, setAccessMessage] = useState('');
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const { userData, authLoading } = useAuth(false);

  useEffect(() => {
    window.isProductTemplateLoaded = true;

    const loadEvent = new Event('load');
    window.dispatchEvent(loadEvent);

    const loadingTimer = setTimeout(() => setShowLoadingMessage(true), 250);

    if (authLoading) return;

    if (!userData) {
      navigate('/account', { state: { from: location } });
      return;
    }

    const checkPermissions = async () => {
      const authToken = localStorage.getItem('auth-token');
      if (!authToken) {
        navigate('/account', { state: { from: location } });
        return;
      }

      try {
        const response = await fetch(
            `${backendUrl}/permissions/check/${productId}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'auth-token': authToken,
              },
            }
        );

        const data = await response.json();
        if (!data.hasPermission) {
          clearTimeout(loadingTimer);
          navigate('/');
          return;
        }

        await fetchProductAndEvent();
      } catch (error) {
        clearTimeout(loadingTimer);
        setError('Failed to check permissions. Please try again.');
      }
    };

    const fetchProductAndEvent = async () => {
      try {
        const token = localStorage.getItem('auth-token');

        const productResponse = await fetch(
            `${backendUrl}/product/get/${productId}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'auth-token': token,
              },
            }
        );
        const productData = await productResponse.json();

        if (productData.success) {
          const product = productData.product;
          if (!product.status) {
            clearTimeout(loadingTimer);
            setError('This product is inactive.');
            return;
          }

          const eventId = product.event._id;
          const eventResponse = await fetch(
              `${backendUrl}/event/get/${eventId}`,
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'auth-token': token,
                },
              }
          );
          const eventData = await eventResponse.json();

          if (eventData.success) {
            clearTimeout(loadingTimer);
            setEvent(eventData.event);
          } else {
            clearTimeout(loadingTimer);
            setError('Event not found');
          }
        } else {
          clearTimeout(loadingTimer);
          setError('Product not found');
        }
      } catch (err) {
        console.error(err);
        clearTimeout(loadingTimer);
        setError('An error occurred while fetching the product or event');
      }
    };

    checkPermissions();

    return () => {
      window.isProductTemplateLoaded = false;

      const removeAllRotateScreens = () => {
        let rotateScreenElement = document.getElementById('rotate-screen');
        while (rotateScreenElement) {
          removeRotateScreen();
          rotateScreenElement = document.getElementById('rotate-screen');
        }
      };

      removeAllRotateScreens();
      clearTimeout(loadingTimer);
    };
  }, [productId, backendUrl, userData, authLoading, navigate, location]);

  useEffect(() => {
    if (event) {
      const videoId = `IR-${productId}`;
      const currentPlayer = videojs.getPlayer(videoId);

      if (currentPlayer) {
        return;
      }

      const eventInformation = {
        allowed_referers: event.allowed_referers || [],
        audio_imgs: event.audio_imgs || [],
        audio_names: event.audio_names || [],
        cam_imgs: event.cam_imgs || [],
        cam_names: event.cam_names || [],
        captions_imgs: event.captions_imgs || [],
        canBeIframe: event.canBeIframe || false,
        product_id: productId,
        video_types: event.video_types || [],
        sources: event.sources || [],
        sources_dash: event.sourcesDash || '',
      };

      initIRPlayer(videoId, eventInformation);
    }
  }, [event, productId]);

  if (accessMessage) {
    return <div style={{ color: 'white' }}>{accessMessage}</div>;
  }

  if (error) {
    return <div style={{ color: 'white' }}>{error}</div>;
  }

  if (event) {
    const videoId = `IR-${productId}`;

    return (
        <video
            id={videoId}
            className="video-js vjs-default-skin vjs-fluid intoreality-player"
            controls
            crossOrigin="anonymous"
            playsInline
            preload="auto"
            data-setup='{"fluid": true}'
        ></video>
    );
  }

  if (showLoadingMessage) {
    return (
        <div style={{color: 'white'}}>
          <p>Loading...</p>
          <p>
            If the product does not load,{' '}
            <a
                href="#"
                style={{color: 'white', textDecoration: 'underline'}}
                onClick={() => window.location.reload()}
            >
              click here.
            </a>
          </p>
        </div>
    );
  }

  return null;
}

export default ProductTemplate;